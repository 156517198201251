import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankOutlined from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckOutlined from "@material-ui/icons/CheckOutlined";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [organization, setOrg] = React.useState("");
  const [orgError, setOrgError] = React.useState(false);
  const [city, setCity] = React.useState("");
  const [cityError, setCityError] = React.useState(false);
  const [comments, setComments] = React.useState("");

  const [isError, setError] = React.useState(false);
  const [isRequired, setRequired] = React.useState(false);

  const [isRegistered, setRegister] = React.useState(false);

  const history = useHistory();
  const navigateTo = () => history.push('/win-page');//eg.history.push('/login');

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const submitPage = async () => {
    
    if(name !== "" && phone !== "" && email !== "" && checked.length > 0 && organization !== ""  && city !== ""){
      window.sessionStorage.setItem("name", name);
      window.sessionStorage.setItem("phone", phone);
      window.sessionStorage.setItem("email", email);
      setError(false)
      setRequired(false)
      
      let formdata = { name: name, phone: phone, email: email, interests:checked, organization: organization, city: city, comments:comments };
      /*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formdata)
      };*/
      /*const response = await fetch('https://api.zlropumpindia.com/index.php/users', {
                                    method: 'post',
                                    redirect: 'follow',
                                    headers: {'Content-Type':'application/json'},
                                    body: JSON.stringify(formdata)
                                  });*/
      //const response = await fetch('https://api.zlropumpindia.com/index.php/users', requestOptions);
      //const data = await response.json();
      //console.log(response.json());
      //this.setState({ postId: data.id });

      fetch('https://api.zlropumpindia.com/index.php/users', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formdata),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data[0].status);
        if(data[0].status === 302)
          setRegister(true);
        else if(!isRegistered)
          navigateTo();
      })
      .catch((error) => {
        console.error('Error:', error);
      });      
    }
    else{
      if(name === "")
        setNameError(true);
      if(phone === "")
        setPhoneError(true);
      if(email === "")
        setEmailError(true);
      if(organization === "")
        setOrgError(true);
      if(city === "")
        setCityError(true);
      if(checked.length === 0)
        setInterestError();
      setError(true);
      setRequired(true);
      console.log(isError)
    }
      
    /*this.props.history.push({
      pathname: "/win-page",
      state: data,
    });*/
  };

  const setInterestError = () => {

  }

  const handleInput = (e) => {
    //console.log("Field ID : " + e.target.id);
    switch (e.target.id) {
      case "name":
        if(e.target.value === "")
          setNameError(true);
        else{
          setName(e.target.value);
          setNameError(false);
        }
        break;
      case "phone":
        if(e.target.value === "")
          setPhoneError(true);
        else{
          setPhone(e.target.value);
          setPhoneError(false);
        }
        break;
      case "email":
        if(e.target.value === "")
          setEmailError(true);
        else{
          setEmail(e.target.value);
          setEmailError(false);
        }
        break;
      case "organization":
        if(e.target.value === "")
          setOrgError(true);
        else{
          setOrg(e.target.value);
          setOrgError(false);
        }
        break;
      case "city":
        if(e.target.value === "")
          setCityError(true);
        else{
          setCity(e.target.value);
          setCityError(false);
        }
        break;
      case "message":
          setComments(e.target.value);
        break;
      default:
        console.log(e.target);
        break;
    }
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
      { isError ?
            <h3 style={{'textAlign':'center','background':'#FFF','marginTop':'0','padding':'15px','color':'red'}}>Please fill in data</h3>
            : ""
          }
        { !isRegistered ?
            
        <GridItem cs={12} sm={12} md={8}>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleInput,
                  }}
                  error = {nameError}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Phone"
                  id="phone"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleInput,
                  }}
                  error = {phoneError}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleInput,
                  }}
                  error = {emailError}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Organization"
                  id="organization"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleInput,
                  }}
                  error = {orgError}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="City"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleInput,
                  }}
                  error = {cityError}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                  <h4 style={{'color':'#aaa','textDecoration':'underline'}}>Interests</h4>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle("Domestic Membrane")}
                        checked={checked.indexOf("Domestic Membrane") !== -1 ? true : false}
                        checkedIcon={<CheckOutlined />}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        required={ isRequired }
                        classes={{
                          checked: classes.checked
                        }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label="Domestic Membrane"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle("HTDS Membrane")}
                        checked={checked.indexOf("HTDS Membrane") !== -1 ? true : false}
                        checkedIcon={<CheckOutlined />}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        classes={{
                          checked: classes.checked
                        }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label="HTDS Membrane"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle("4 inch Membrane")}
                        checked={checked.indexOf("4 inch Membrane") !== -1 ? true : false}
                        checkedIcon={<CheckOutlined />}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        classes={{
                          checked: classes.checked
                        }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label="4 inch Membrane"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle("8 inch Membrane")}
                        checked={checked.indexOf("8 inch Membrane") !== -1 ? true : false}
                        checkedIcon={<CheckOutlined />}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        classes={{
                          checked: classes.checked
                        }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label="8 inch Membrane"
                  />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  onChange: handleInput,
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary" onClick={() => submitPage()}>
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
        : <h3 style={{'textAlign':'center','background':'#FFF','marginTop':'0','padding':'15px','color':'red'}}>You have already participated. Thank you!</h3>
      }
      </GridContainer>
    </div>
  );
}
